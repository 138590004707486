import { ReactNode } from 'react';
import './styles.scss';

type QuantifyHighlightsProps = {
  className?: string;
  values: Array<{
    value: ReactNode;
    label: ReactNode;
  }>;
};

export const QuantifyHighlights = (props: QuantifyHighlightsProps) => (
  <div
    className={`${props.className} border-1 rounded-md highlight-quantify-box`}
  >
    {props.values.map((quantify, idx) => (
      <>
        <div className="quantify">
          <h1 className="regular m-0">{quantify.value}</h1>
          <span className="text-sm regular">{quantify.label}</span>
        </div>

        {idx !== props.values.length - 1 && (
          <div className="divider">
            <div className="divider-small" />
          </div>
        )}
      </>
    ))}
  </div>
);
