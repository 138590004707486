import { ReactNode } from 'react';
import { Block } from '@/components/CaseStudies/Block';
import { caseStudiesNavigationData } from '@/components/CaseStudies/data';
import CaseStudyIcon from '@/static/assets/icons/CaseStudy/icn-case-study.svg';

import './styles.scss';

type CaseStudyProps = {
  id: string;
  title: ReactNode;
  logo: {
    src: string;
    alt: string;
  };
  description: ReactNode;
  keyAkuityPlatformBenefits: string[];
  content: Array<{
    title: ReactNode;
    description: ReactNode;
  }>;
  highlight: ReactNode;
};

export const CaseStudy = (props: CaseStudyProps) => (
  <div className="v2 case-study">
    <span className="border-2 rounded-xl tag">
      <CaseStudyIcon />
      <span className="regular">Case Study</span>
    </span>

    <h1 className="text-center case-study-title">{props.title}</h1>

    <div className="divider">
      <div className="divider-small" />
    </div>

    <div className="company-brief">
      <img {...props.logo} />
      <div className="divider">
        <div className="divider-small" />
      </div>
      <p>{props.description}</p>
    </div>

    <div className="benefits border-1 rounded-md">
      <p className="bold">Key Akuity Platform Benefits</p>
      <ul>
        {props.keyAkuityPlatformBenefits.map((benefit, idx) => (
          <li key={idx}>{benefit}</li>
        ))}
      </ul>
    </div>

    <div className="content-container">
      <div className="block">
        <Content {...props.content[0]} />
        <div className="highlight">
          <div className="float-left">{props.highlight}</div>
        </div>
      </div>
      {props.content.slice(1).map((c, idx) => (
        <Content key={idx} {...c} />
      ))}
    </div>

    <div className="more">
      <div className="divider">
        <div className="divider-small" />
      </div>

      <h3>More Customer Stories</h3>

      <div className="blocks">
        {Object.entries(caseStudiesNavigationData)
          .filter(([id]) => id !== props.id)
          .map(([id, data]) => (
            <Block {...data} key={id} className={id} titleAs="h6" />
          ))}
      </div>
    </div>
  </div>
);

const Content = (props: CaseStudyProps['content'][0]) => (
  <div className="content">
    <h3>{props.title}</h3>
    {props.description}
  </div>
);
