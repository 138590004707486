import React from 'react';
import { navigate } from 'gatsby';

import '@/styles/v2/index.scss';
import './CaseStudies.scss';

export const Block = (props: {
  logo: { src: string; alt: string };
  title: string;
  link: string;
  className?: string;
  titleAs?: string;
}) => {
  const titleEle = React.createElement(props.titleAs || 'h4', {}, props.title);
  return (
    <div
      className={`block border-1 rounded-md ${props.className}`}
      onClick={() => navigate(props.link)}
    >
      <img {...props.logo} />

      <div className="divider">
        <div className="divider-small" />
      </div>

      {titleEle}

      <a className="bold text-sm" href={props.link}>
        Read story →
      </a>
    </div>
  );
};
