import { caseStudiesNavigationData } from '@/components/CaseStudies/data';
import Layout from '@/components/Layout';
import { CaseStudy } from '@/sections/CaseStudy';
import { QuantifyHighlights } from '@/sections/CaseStudy/highlights/quantify';
import { seoConfiguration } from '@/utils/seo-config';

const whatnotData = caseStudiesNavigationData.whatnot;

if (!whatnotData) {
  throw new Error('Please add whatnot case study data');
}

const WhatnotCaseStudyPage = () => (
  <Layout {...seoConfiguration['case-studies'].whatnot}>
    <CaseStudy
      id="whatnot"
      {...whatnotData}
      description={`Whatnot is the largest livestream shopping platform in North America and Europe to buy, sell, and discover the things you love. Buyers and sellers connect in real time to chat, build relationships, and run live auctions, creating a fun and personalized way to shop online. Founded in 2019 by collectors, the platform has quickly grown to support a huge range of different categories globally. From collectibles like trading cards, comic books, and memorabilia to fashion, beauty, electronics, and even live plants, Whatnot has something for everyone. For more information, please visit www.whatnot.com or download the Whatnot app on Android or iOS.`}
      keyAkuityPlatformBenefits={[
        'Enhanced security thanks to the agent architecture',
        'Premium support from the creators of Argo',
        'Increased shipping velocity'
      ]}
      content={[
        {
          title: 'Akuity Brings Extra Security and Premium Support',
          description: (
            <>
              <p>
                As Whatnot&apos;s business grew, ensuring platform stability and
                reliability became paramount. Their manual deployment processes,
                including manual release cuts and limited deployment frequency,
                were no longer scalable. The need to automate deployments and
                increase release velocity became a strategic imperative for the
                organization.
              </p>

              <p>
                To address these challenges, Whatnot embarked on an effort to
                automate their deployment pipelines and increase release
                frequency. Initially, Whatnot&apos;s deployment process was
                manual, with releases cut twice daily. They transitioned to
                automated deployments using Argo CD, improving efficiency and
                enabling more frequent releases. Akuity&apos;s managed Argo CD
                offering provided enhanced security and premium support directly
                from the creators of Argo CD, ensuring stability and
                reliability.
              </p>
            </>
          )
        },
        {
          title: 'Ship Faster and Focus on Reliability',
          description: (
            <>
              <p>
                By partnering with Akuity and leveraging their managed Argo CD
                platform, Whatnot successfully automated deployment processes.
                They increased release velocity by 20x and improved platform
                stability and reliability, evidenced by a 62% reduction in
                severe incidents. This strategic adjustment enabled Whatnot to
                continue fostering a vibrant social commerce community while
                staying agile and responsive to market demands.
              </p>
            </>
          )
        }
      ]}
      highlight={
        <QuantifyHighlights
          values={[
            {
              value: '20x',
              label: 'Increased Velocity'
            },
            {
              value: '62%',
              label: 'Reduction in Severe Accidents'
            }
          ]}
        />
      }
    />
  </Layout>
);

export default WhatnotCaseStudyPage;
