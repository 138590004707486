import CowbellLogo from '@/static/assets/images/CaseStudies/logo-cowbell.svg';
import WhatnotLogo from '@/static/assets/images/CaseStudies/logo-whatnot.png';

export const caseStudiesNavigationData = {
  cowbell: {
    title: 'Akuity Manages Argo CD for Cowbell',
    link: '/case-studies/cowbell',
    logo: { src: CowbellLogo, alt: 'Cowbell logo' }
  },
  whatnot: {
    title: 'Akuity Helps Whatnot Ship Often and Focus on Reliability',
    link: '/case-studies/whatnot',
    logo: { src: WhatnotLogo, alt: 'Whatnot logo' }
  }
};
